import * as React from 'react'
import { shape, string } from 'prop-types'
import Legal from '@/templates/Legal'
import * as restAPI from "../utils/endpoints";
function TermsAndConditions({ location }) {
	return (
		<Legal
			pathname={location.pathname}
			type="terms"
			title="Terms and conditions"
			url={restAPI.GET_TERMS}
		/>
	)
}

TermsAndConditions.propTypes = {
	location: shape({
		pathname: string
	}).isRequired
}

export default TermsAndConditions
